import React from "react"
import Wrapper from "./Wrapper"
import NpmCopySnake from "./NpmCopySnake"
import languages from "../languages.json"

import snakeScreenshotGimp from "../img/snakeScreenshotGimp.PNG"

export default function PortfolioItemSnake(props){
    const txt = languages.PortfolioItemSnake;
    const langIndex = props.lang.index;

    return(
        <div>
            <section className="intro">
                <h1 className="section__title section__title--intro">
                {txt.sectionTitle[langIndex]}<strong>{txt.sectionTitleStrong[langIndex]}</strong>
                </h1>
                <p className="section__subtitle section__subtitle--intro">{txt.sectionSubtitle[langIndex]}</p>
                <img src={snakeScreenshotGimp} alt="" className="intro__img" />
            </section>

            <div className="portfolio-item-individual">
                <p>{txt.paragraph1[langIndex]}</p>
                <p>{txt.paragraph2[langIndex]}</p>
                <NpmCopySnake />
                <p>{txt.paragraph3[langIndex]}</p>
            </div>
            <Wrapper />
        </div>
    )
}

