import React from "react"

export default function Intro(props){
    return(
        <div className="intro__bg">
            <section className="intro" id="home">
                <h2 className="section__title section__title--intro">
                Nikolaj <strong>Stuckmann</strong>
                </h2>
                <p className="section__subtitle section__subtitle--intro">it-dev/engineer</p>
                <img className="intro__img" src={require("../img/stuckmann-01.jpg")} alt="Niko Stuckmann looking very handsome" />
            </section>
        </div>
    )
}