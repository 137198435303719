import React from "react"
import {Link} from "react-router-dom"
import languages from "../languages.json"

export default function MyServicesDev(props){
    const txt = languages.MyServicesDev
    const langIndex = props.lang.index

    return(
        <section className="my-services-dev" id="services">
            <h2 className="section__title section__title--services-dev">{txt.sectionTitle[langIndex]}</h2>
            <div className="services">
                <div className="service-dev">
                    <h3>{txt.serviceDevTitle1[langIndex]}</h3>
                    <p>{txt.serviceDevText1[langIndex]}</p>
                </div> {/* service*/}
                
                <div className="service-dev">
                    <h3>{txt.serviceDevTitle2[langIndex]} <i className="fab fa-react"></i></h3>
                    <p>{txt.serviceDevText2[langIndex]}</p>
                </div> {/* service*/}
                
                <div className="service-dev">
                    <h3>{txt.serviceDevTitle3[langIndex]}</h3>
                    <p>{txt.serviceDevText3[langIndex]}</p>
                </div> {/* service*/}
            </div> {/* services*/}
            
            <Link className="btn" to="/work">
                {txt.buttonText[langIndex]}
            </Link>
            
        </section>
    )
}