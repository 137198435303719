import React from "react"
import aboutMeImg from "../img/stuckmann-02.jpg"
import languages from "../languages.json"

export default function AboutMe(props){
    const txt = languages.AboutMe
    const langIndex = props.lang.index

    return(
        <div className="about-me__bg">
            <section className="about-me" id="about">
                <h2 className="section__title section__title--about">{txt.sectionTitle[langIndex]}</h2>
                <p className="section__subtitle section__subtitle--about">{txt.sectionSubtitle[langIndex]}</p>
                
                <div className="about-me__body">
                    <p>{txt.paragraph1[langIndex]}</p>
                    <p>{txt.paragraph2[langIndex]}</p>
                    <p>{txt.paragraph3[langIndex]}</p>
                    <p>{txt.paragraph4[langIndex]}</p>
                    <p>{txt.paragraph5[langIndex]}</p>
                    <p>{txt.paragraph6[langIndex]}</p>
                </div>
                
                <img src={aboutMeImg} alt="Niko Stuckmann standing next to welding guns" className="about-me__img"/>
            </section> 
        </div>
    )
}