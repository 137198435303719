import React from "react"
import {Link} from "react-router-dom"
import languages from "../languages.json"
import snakeScreenshotGimp from "../img/snakeScreenshotGimp.PNG"
import dashboardThumbnail from "../img/react_dashboard_tumbnail.png"
import renovatorThumbnail from "../img/renovator_tumbnail.png"


export default function MyWork(props){
    const txt = languages.MyWork
    const langIndex = props.lang.index

    return(
        <section className="my-work" id="work">
            <h2 className="section__title section__title--work">{txt.sectionTitle[langIndex]}</h2>
            <p className="section__subtitle section__subtitle--work">{txt.sectionSubtitle[langIndex]}</p>
            
            <div className="portfolio">
                {/* Portfolio item Snake */}
                <Link to="/snake" className="portfolio__item">
                    <img src={snakeScreenshotGimp} alt="" className="portfolio__img" />
                </Link>
                {/* Portfolio item Dashboard*/}
                <Link to="/dashboard" className="portfolio__item">
                    <img src={dashboardThumbnail} alt="" className="portfolio__img" />
                </Link>
                {/* Portfolio item Renovator*/}
                <Link to="/renovator" className="portfolio__item">
                    <img src={renovatorThumbnail} alt="" className="portfolio__img" />
                </Link>
            </div> {/* portfolio*/}
        </section>
    )
}