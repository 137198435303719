import React from "react";
import Confetti from "react-confetti";
import {useMeasure} from 'react-use';
import Snake from "react_jungle_snake";
import woodCarving from "../img/woodCarving2.jpg";
import woodSmall from "../img/woodSmall.jpg";

export default function Wrapper(){
    const [renderConfetti, setRenderConfetti] = React.useState(false);
 
    //Misst wrapper dynamisch
    const [wrapperRef, {right}] = useMeasure();
    //default { x, y, width, height, top, right, bottom, left }

    //iO geht bis Footer oder unterer Rand Handheld
    const confettiHeight= document.body.scrollHeight - 243; //immer von Oben: Gesamthöhe - Footer
    const confettiWidth= right; //Width von wrapper
    
    //zieht die Game Daten aus Snake comp und startet Confetti
    function getGameData(data){
        if(data.gameover && data.newHighscore){
            setRenderConfetti(true);
        } else {
            setRenderConfetti(false);
        };
    };

    const confettiSource = {
        x: 0, //iO. linker Rand
        y: document.body.scrollHeight - 600, //iO. Höhe von oben
        w: right, //iO. Breite nach rechts von x
        h: 0 //iO. Höhe nach unten von y
    };

    return(
        <div ref={wrapperRef} className="wrapper">
            <Snake 
                sendGameData={getGameData} 
                styles={ 
                    {
                    handheldBackgroundImg: woodSmall, 
                    playboardBackgroundImg: woodCarving 
                    }
                }
            />
            {renderConfetti && 
                <Confetti 
                    confettiSource={confettiSource} 
                    height={confettiHeight}
                    width={confettiWidth} 
                />
            }
        </div>
    );
};
