import React from "react"
import languages from "../languages.json"

import dashboardTumbnail from "../img/react_dashboard_tumbnail.png"

export default function PortfolioItemDashboard(props){
    const txt = languages.PortfolioItemDashboard;
    const langIndex = props.lang.index;

    return(
        <div>
            <section className="intro">
                <h1 className="section__title section__title--intro">
                {txt.sectionTitle[langIndex]}<strong>{txt.sectionTitleStrong[langIndex]}</strong>
                </h1>
                <p className="section__subtitle section__subtitle--intro">{txt.sectionSubtitle[langIndex]}</p>
                <img src={dashboardTumbnail} alt="" className="intro__img" />
            </section>

            <div className="portfolio-item-individual">
                <p>{txt.paragraph1[langIndex]}</p>
                <p>{txt.paragraph2[langIndex]}</p>
                <p>{txt.paragraph3[langIndex]}</p>
            </div>

            <div className="btn_dashboard_container">
                <a className="btn btn_dashboard" href="https://dashboard.nikostuckmann.de/" target="_blank" rel="noopener noreferrer">Dashboard Preview</a>
            </div>
        </div>
    )
}