import React from "react"
import {Link} from "react-router-dom"
import languages from "../languages.json"

import mail from "../img/mail.png"

export default function Footer(props){
    const txt = languages.Footer
    const langIndex = props.lang.index

    return(
        <footer className="footer">
            <img className="footer__mail" src={mail} alt="mail broken, please contact me on Xing"/>
            <ul className="social-list">
                <li className="social-list__item">
                    <a className="social-list__link" href="https://www.xing.com/profile/Nikolaj_Stuckmann" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-xing-square"></i>
                    </a>
                </li>
                <li className="social-list__item">
                    <a className="social-list__link" href="https://github.com/NikoStu" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-github"></i>
                    </a>
                </li>
                <li className="social-list__item">
                    <a className="social-list__link" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-react"></i>
                    </a>
                </li>
            </ul>
            <Link to="/imprintDataProtection" className="footer__link">{txt.imprintDataProtection[langIndex]}</Link>
        </footer>
    )
}