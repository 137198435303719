import React from "react"
import {Route, Routes} from "react-router-dom"

import Header from "./components/Header"
import Intro from "./components/Intro"
import MyServicesDev from "./components/MyServicesDev"
import MyServicesEng from "./components/MyServicesEng"
import AboutMe from "./components/AboutMe"
import MyWork from "./components/MyWork"
import DSGVO from "./components/DSGVO"
import Footer from "./components/Footer"
import PortfolioItem from "./components/PortfolioItem"
import PortfolioItemSnake from "./components/PortfolioItemSnake"
import PortfolioItemDashboard from "./components/PortfolioItemDashboard"
import PortfolioItemRenovator from "./components/PortfolioItemRenovator"

export default function App(){
    /*toDo  
    Pflicht:

    Kür:
    -Nav leicht transparent
    -Hamburger fixe position unabhängig vom Scrollen
    */

    
    const [showNav, setShowNav] = React.useState(false)
    const [lang, setLang] = React.useState({name:"german", index:0})

    function changeLang(language){
        if(language === "german"){
            setLang({name:"german", index:0})
        } else if (language === "english"){
            setLang({name:"english", index:1})
        } else if (language === "japanese"){
            setLang({name:"japanese", index:2})
        }
    }

    function toggleNav(){
        setShowNav(oldState => !oldState)
    }
    
    return(
        <div className={showNav ? "nav-open" : "" }>
            <Header flagClick={changeLang} handleClick={toggleNav} lang={lang}/>
            <Routes>
                <Route exact path="/" element={
                    <div>
                        <Intro /> 
                        <MyServicesDev lang={lang}/>
                        <MyServicesEng lang={lang}/>
                    </div>} 
                />
                <Route exact path="/work" element={<MyWork lang={lang}/>}/>
                <Route exact path="/about" element={<AboutMe lang={lang}/>}/>
                <Route exact path="/imprintDataProtection" element={<DSGVO lang={lang}/>}/>
                <Route exact path="/portfolioItem" element={<PortfolioItem lang={lang}/>}/>
                <Route exact path="/snake" element={<PortfolioItemSnake lang={lang}/>}/>
                <Route exact path="/dashboard" element={<PortfolioItemDashboard lang={lang}/>}/>
                <Route exact path="/renovator" element={<PortfolioItemRenovator lang={lang}/>}/>
            </Routes>
            <Footer lang={lang}/>
        </div>
    )
}