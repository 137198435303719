import React from "react"
import {Link} from "react-router-dom"
import languages from "../languages.json"

export default function Nav(props){
    const txt = languages.Nav
    const langIndex = props.lang.index

    React.useEffect(() => {
        const navLinks = document.querySelectorAll('.nav__link')
        navLinks.forEach(link => {
            link.addEventListener('click', () => {
                props.handleClick();
            })
        })

        return function cleanUpNavELs(){
            navLinks.forEach(link => {
                link.removeEventListener('click', () => {
                    props.handleClick();
                }) 
        })}
    }, [])


    return(
        <nav className="nav">
            <ul className="nav__list">
                <li className="nav__item">
                    <Link className="nav__link" to="/">{txt.home[langIndex]}</Link>
                </li>
                <li className="nav__item">
                    <Link className="nav__link" to="/work">{txt.work[langIndex]}</Link>
                </li>
                <li className="nav__item">
                    <Link className="nav__link" to="/about">{txt.aboutMe[langIndex]}</Link>
                </li>
            </ul>
        </nav>
    )
}