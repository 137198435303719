import React from "react"
import Nav from "./Nav"
import deFlag from "../img/de-flag.png"
import gbFlag from "../img/gb-flag.png"
import jpFlag from "../img/jp-flag.png"

export default function Header(props){
    const lang = props.lang
    return(
        <header>
            <div className="flags">
                <img className={lang.index === 0 ? "flag flag__outline" : "flag"} onClick={()=>props.flagClick("german")} src={deFlag} alt="Deutsch" />
                <img className={lang.index === 1 ? "flag flag__outline" : "flag"} onClick={()=>props.flagClick("english")} src={gbFlag} alt="English" />    
                <img className={lang.index === 2 ? "flag flag__outline" : "flag"} onClick={()=>props.flagClick("japanese")} src={jpFlag} alt="日本語" />
            </div>
            <button className="nav-toggle" onClick={props.handleClick} aria-label="toggle navigation">
                <span className="hamburger"></span>
            </button>

            <Nav handleClick={props.handleClick} lang={lang}/>
        </header>
    )
}