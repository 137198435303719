import React from "react"
import languages from "../languages.json"

export default function MyServicesEng(props){
    const txt = languages.MyServicesEng
    const langIndex = props.lang.index

    return(
        <section className="my-services-eng" id="services">
            <h2 className="section__title section__title--services-eng">{txt.sectionTitle[langIndex]}</h2>
            <div className="services">
                <div className="service-eng">
                    <h3>{txt.serviceEngTitle1[langIndex]}</h3>
                    <p>{txt.serviceEngText1[langIndex]}</p>
                </div> {/* service*/}

                <div className="service-eng">
                    <h3>{txt.serviceEngTitle2[langIndex]}</h3>
                    <p>{txt.serviceEngText2[langIndex]}</p>
                </div> {/* service*/}

                <div className="service-eng">
                    <h3>{txt.serviceEngTitle3[langIndex]}</h3>
                    <p>{txt.serviceEngText3[langIndex]}</p>
                </div> {/* service*/}
            </div> {/* services*/}
        </section>
    )
}