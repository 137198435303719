import React from "react"
import ReactDOM from "react-dom"
import {HashRouter as Router} from "react-router-dom"

import ScrollToTop from "./components/ScrollToTop"

import App from "./App"
import "./App.css"

ReactDOM.render(<Router hashType="slash">
                    <ScrollToTop />
                    <App />
                </Router>, 
                document.getElementById("root"))